<template>
    <div>
        <CRow>
            <CCol lg="12">
                <CCard>
                    <CCardHeader>
                        <slot name="header">
                            <div class="prt-card-header">
                                <div class="header-naming">
                                    <CIcon name="cil-grid"/>
                                    Alle Abonnements
                                </div>
                                <div class="header-button">
                                    <CButton
                                            to="realms/new"
                                            name="backagain"
                                            size="sm"
                                            key="key"
                                            color="dark">
                                        <CIcon size="sm" name="cil-plus"/>
                                        <span> Instanz hinzufügen</span>
                                    </CButton>
                                </div>
                            </div>
                        </slot>
                    </CCardHeader>
                    <CCardBody>
                        <CDataTable
                                :items="tableData"
                                :fields="getTableFields()"
                                hover
                                striped
                                bordered
                                fixed
                                column-filter
                                table-filter
                                items-per-page-select
                                sorter
                                pagination
                                :loading="tableShowLoading"
                        >
                            <template #status="{item}">
                                <td>
                                    <CBadge :color="getTableBadge(item.status)">
                                        {{item.status}}
                                    </CBadge>
                                </td>
                            </template>
                            <template #show_details="{item, index}">
                                <td class="py-2">
                                    <CButton
                                            :to="'realms/edit/' + item.id"
                                            color="primary"
                                            variant="outline"
                                            square
                                            size="sm"
                                    >
                                        Edit
                                    </CButton>
                                </td>
                            </template>
                        </CDataTable>
                    </CCardBody>
                </CCard>
            </CCol>
        </CRow>
    </div>
</template>
<style scoped>
    .prt-card-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .prt-card-header > div {
        text-align: right;
    }
</style>
<script>
    import axios from 'axios'

    export default {
        name: 'Realms',
        components: {},
        data() {
            return {
                tableData: [],
                tableShowLoading: true,
            }
        },
        mounted() {
            axios.get('/realm')
                .then(response => {
                    this.tableData = response.data
                    this.tableShowLoading = false
                })
                .catch(err => console.error('Problem while fetching realms', err))
        },
        methods: {
            getTableFields() {
                return [
                    {key: 'id', label: 'ID', _style: 'width: 10%'},
                    'name',
                    'wordpressUrl',
                    'status',
                    {
                        key: 'show_details',
                        label: '',
                        _style: 'width:1%',
                        sorter: false,
                        filter: false
                    }
                ]
            },
            getTableBadge (status) {
                return status === 'ACTIVE' ? 'success'
                    : status === 'INACTIVE' ? 'secondary'
                        : status === 'PENDING' ? 'warning'
                            : status === 'BANNED' ? 'danger' : 'primary'
            }
        }
    }
</script>
